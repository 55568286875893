import { constructUrl } from 'models/utils'
import { getConfigOptions, getMetaOptions } from 'global-content/config'
import { AVAILABILITY_FLAGS, SALE_STATES, ORDERABLE_STATES } from 'utils/constants'
import { sortCategories } from 'utils/sortCategories'

/*
  Glossary via Examples
  =====================

  Product
    Cashmere Crew Sweater
  Option
    Color
    Size
  Option Values
    Color: White, Gray, Black
    Size: Small, Medium, Large
  Variant:
    Small White Cashmere Crew Sweater
  SKU:
    2564-9456  (this is the identifier for the variant, and the terms are
                some what used interchangeably)

  There are 3 flavors of product data:
    - Product API for Product Details Page (PDP)
    - Algolia for Product Listing Page (PLP)
    - Cart services
  While the data is the same, they are formatted and optimized for their
  corresponding use case.

  We try to unify commmon parts of these 3 product variations in order to
  maintain the same format/structure so that components can be re-used.
  *HOWEVER*, there are no validations in place to enforce that they are
  truly the same.
 */

// https://bitbucket.org/localised-eng/api-specs/src/master/schemas/product.json
export function toDetailProduct(product) {
  const {
    categories,
    ...rest
  } = product
  const filtered = formatDetailOptions(product.options)

  return {
    ...rest,
    // as long as there are some filtered options left return those
    // otherwise use the default unfiltered options
    options: filtered.length ? filtered : product.options,
    ...formatPrices(product),
    categories: sortCategories(categories),
    sizeAndFit: getSizeAndFit(product),
  }
}

// https://bitbucket.org/localised-eng/api-specs/src/master/schemas/sku.json
export function toListingProduct(data, getPromotions) {
  const {
    availabilityFlag,
    brand,
    categories,
    categoryPageIds,
    collections,
    color,
    name,
    objectID,
    offsettedPosition,
    optionLevels,
    options,
    price,
    priceRange,
    productAvailability,
    productId,
    queryID,
    size,
    sku,
    slug,
  } = data

  const currencyCountry = getMetaOptions(`currencyCountry`)
  const mainImage = getMainImage(data)
  const shippingDateRange = getProductShippingDateRange(data)

  // Needs to do a first pass to sanitize the data so it is in the right shape
  // for further sanitizing helper functions, e.g. getPromotions()
  const sanitized = {
    // same as product
    availability: AVAILABILITY_FLAGS[availabilityFlag],
    brand,
    collections,
    categoryPageIds,
    id: encodeURIComponent(productId),
    name,
    priceRange: priceRange[currencyCountry],
    slug,
    // same field name, need to confirm value structure
    categories: categories && sortCategories(categories.map(category => category.tag)),
    objectID,
    optionLevels,
    // unique to listing product
    availabilityFlag,
    color,
    options: formatOptions(options),
    mainImage: mainImage,
    price: {
      sale: {
        total: price[currencyCountry].sale,
      },
      list: {
        total: price[currencyCountry].list,
      },
    },
    productAvailability,
    queryID,
    offsettedPosition: offsettedPosition,
    onSale: price[currencyCountry].onSale,
    sale: SALE_STATES[price[currencyCountry].onSale],
    shippingDateRange,
    size,
    sku,
    url: constructUrl({ productId, slug }),
  }

  return {
    ...sanitized,
    promotions: getPromotions(sanitized),
  }
}

function getMainImage(data) {
  const {
    optionLevels,
    options,
  } = data
  const firstLevel = optionLevels[0]

  if (data[firstLevel]) {
    const option = options.find(x => x.slug === data[firstLevel].slug)

    if (option) {
      return option.mainImage
    }
  }

  return `/images/no-image/no-image-available.jpg`
}

export function formatOptions(options) {
  const withMedia = options.map(option => ({
    ...option,
    media: {
      large: [option.mainImage],
      video: [],
    },
  }))

  return filterOOS(withMedia)
}

function filterOOS(options) {
  const showOOS = getConfigOptions(`variant.show.oos`)

  if (showOOS) {
    return options
  }

  return options.filter(option => ORDERABLE_STATES.includes(option.availability))
}

function formatDetailOptions(options) {
  return filterOOS(options)
    .map(option => {
      if (option.options) {
        return {
          ...option,
          options: formatDetailOptions(option.options),
        }
      }

      return option
    })
}

function formatPrices(product) {
  const {
    price = {},
    priceMax,
    priceMin,
    priceRange,
  } = product
  const {
    list = {},
    sale = {},
  } = price
  const {
    listMin,
    listMax,
    saleMin,
    saleMax,
  } = priceRange

  return {
    price: {
      list: {
        duty: list.duty,
        tax: list.tax,
        total: list.total,
      },
      sale: {
        duty: sale.duty,
        tax: sale.tax,
        total: sale.total,
      },
    },
    priceRange: {
      min: priceMin,
      max: priceMax,
      listMin: listMin,
      listMax: listMax,
      saleMin: saleMin,
      saleMax: saleMax,
    },
  }
}

function getSizeAndFit(selectedVariant) {
  const { clientProperties = {} } = selectedVariant
  const { sizeAndFit } = clientProperties

  if (sizeAndFit) {
    const removeNull = sizeAndFit.map(row => row.filter((cell, i) => {
      if (i === 0 || cell) {
        return true
      }
    }))

    return removeNull
  }

  return undefined
}

// TODO: make a unit test for this
export function getProductShippingDateRange(productData) {
  const {
    shipDateMin,
    shipDateMax,
  } = productData

  if (shipDateMin === undefined || shipDateMax === undefined) {
    return null
  }

  let minRange = null
  let maxRange = null
  for (const skuId in shipDateMin) {
    const skuMin = Number(shipDateMin[skuId])
    const skuMax = Number(shipDateMax[skuId])

    if (minRange === null || skuMin < minRange) {
      minRange = skuMin
    }

    if (maxRange === null || skuMax > maxRange) {
      maxRange = skuMax
    }
  }

  const shippingDateRange = { minRange, maxRange }
  return shippingDateRange
}
