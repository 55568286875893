import _filter from 'lodash/filter'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import uniq from 'lodash/uniq'
import { getConfigOptions, getMetaOptions } from 'global-content/config'
import { sanitize } from 'utils/sanitize'
import { webLabels } from './fixtures'
import { toSlug } from 'utils/toSlug'
import { reportError } from 'services/reportError'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import omit from 'lodash/omit'

//  type SimpleFilter = Array<string>

//  type DetailedFilter = {
//    includes: SimpleFilter;
//    excludes: SimpleFilter;
//  }

//  interface getProducts {
//    analytics?: String;
//    index: AlgoliaIndex;
//    options: {
//      facets?: Array<FacetKey>;
//      filters: {
//        brand?: SimpleFilter | DetailedFilter;
//        categories?: SimpleFilter | DetailedFilter;
//        collections?: SimpleFilter | DetailedFilter;
//        color?: SimpleFilter | DetailedFilter;
//        priceRange?: [min, max?];
//        size?: SimpleFilter | DetailedFilter;
//        slug?: SimpleFilter | DetailedFilter;
//        onSale?: Boolean;
//      }
//    };
//    hitsPerPage?: Number;
//    includeOutOfStock?: Boolean;
//    page?: Number;
//    query?: String;
//    selectedSort?: string;
//  }

const extractDefaultCollection = (options) => {
  // If it is an array (all for includes), sort by the first one
  if (options?.filters?.collections?.length) {
    return options.filters.collections[0]
  }
  if (options?.filters?.collections?.includes?.length) {
    return options.filters.collections.includes[0]
  }
}

export const getMultiProducts = async(queries = []) => {
  const queryParameters = queries.map(({
    indexName,
    ...query
  }) => {
    return {
      indexName,
      ...generateSearchParameters(query),
    }
  })
  const queryChunks = chunk(queryParameters, 50)
  const products = await Promise.all(
    queryChunks.map(async(queryChunk) => {
      const requests = []
      const additionalParameters = []
      queryChunk.forEach(({
        indexName,
        normalizedFilters,
        sortOptionalFilters,
        ...params
      }) => {
        requests.push({ indexName, params })
        additionalParameters.push({
          normalizedFilters,
          sortOptionalFilters,
        })
      })
      const resp = await window.$content.algoliaMultipleQueries(requests)
      return queries.map((query, i) => {
        const defaultCollectionSort = extractDefaultCollection(query.options)
        const {
          normalizedFilters = {},
          sortOptionalFilters = {},
        } = additionalParameters[i]
        let facets = getFacets(query.options.facets)
        const formattedResults = formatResults({
          format: query.format,
          language: query.language,
          defaultCollectionSort,
          normalizedFilters,
          optionalFilters: sortOptionalFilters,
          response: resp.results[i],
          facets,
          bannerOptions: {
            nbPageBanners: query.options.nbPageBanners || 0,
            nbPreviousBanners: query.options.nbPreviousBanners || 0,
          },
        })
        return {
          ...formattedResults,
        }
      })
    })
  )
  return flatten(products)
}
export const getProducts = ({
  analytics = false,
  clickAnalytics,
  format = true,
  index,
  language,
  options,
  priorityOptions = {},
}) => {
  // prioritizedFilters are the filters we use when we have a featured sort which prepends its results to the listing items
  // so we make two calls to algolia and stitch the results together
  let optionalFilters = getPrioritizedFilters(priorityOptions)

  // normalize the filters to ensure they are the same shape. We have an implicit 'includes' to make writing basic filters simpler
  // { categories: [`womens`] } normalized becomes
  // { categories: { includes: [`womens`] } }
  let normalizedFilters = normalizeFiltersObject(options.filters)
  let facets = getFacets(options.facets)

  if (window.$dev.flags.listAllProducts) {
    facets = [`brand.tag`, `color.tag`, `size.tag`, `price.sale`]
    options.filters.brand = []
    options.filters.categories = []
    options.filters.collections = []
    if (normalizedFilters && normalizedFilters.brand) {
      normalizedFilters.brand.includes = []
    }
    if (normalizedFilters && normalizedFilters.categories) {
      normalizedFilters.categories.includes = []
    }
    if (normalizedFilters && normalizedFilters.collections) {
      normalizedFilters.collections.includes = []
    }
    if (optionalFilters && optionalFilters.brand) {
      optionalFilters.brand.includes = []
    }
    if (optionalFilters && optionalFilters.categories) {
      optionalFilters.categories.includes = []
    }
    if (optionalFilters && optionalFilters.collections) {
      optionalFilters.collections.includes = []
    }
    if (optionalFilters && optionalFilters.prioritizedProductIds) {
      optionalFilters.prioritizedProductIds.includes = []
    }
  }

  // If the prioritizedResults did not specific which collection to sort with, use the current filter
  const defaultCollectionSort = extractDefaultCollection(options)

  return fetchProducts({
    analytics,
    clickAnalytics,
    facets,
    index,
    options,
    priorityOptions,
  })
    .then((response) => {
      const results = formatResults({
        format,
        language,
        optionalFilters,
        defaultCollectionSort,
        response,
        bannerOptions: {
          nbPageBanners: options.nbPageBanners || 0,
          nbPreviousBanners: options.nbPreviousBanners || 0,
        },
      })

      return {
        ...results,
        userData: response.userData,
      }
    })
    .catch((error) => handleError({ error, options }))
}

/* implementation functions */

export function getPrioritizedFilters({
  selectedSort,
  featuredProductIds,
  featuredCollections,
} = {}) {
  return mergeFiltersObject([
    getFeaturedProductIdFilters(featuredProductIds),
    getSelectedSortFilters(selectedSort),
    getFeaturedCollectionsFilters(featuredCollections),
  ])
}

export function getFeaturedProductIdFilters(featuredProductIds) {
  return featuredProductIds && featuredProductIds.length && {
    prioritizedProductIds: {
      includes: featuredProductIds,
    },
  }
}

export function getSelectedSortFilters(selectedSort) {
  const sortOptions = getConfigOptions(`sortOptions`)
  const selectedSortOptions = sortOptions.find(option => option.value === selectedSort)

  return (
    selectedSortOptions &&
    selectedSortOptions.prioritizedResults &&
    normalizeFiltersObject(selectedSortOptions.prioritizedResults)
  )
}

export function getFeaturedCollectionsFilters(featuredCollections = []) {
  return featuredCollections.length && {
    collections: {
      includes: [...featuredCollections],
    },
  }
}

function generateSearchParameters({
  analytics,
  clickAnalytics,
  options = {},
  priorityOptions = {},
}) {
  let optionalFilters = getPrioritizedFilters(priorityOptions)

  // normalize the filters to ensure they are the same shape. We have an implicit 'includes' to make writing basic filters simpler
  // { categories: [`womens`] } normalized becomes
  // { categories: { includes: [`womens`] } }
  let normalizedFilters = normalizeFiltersObject(options.filters)
  let facets = getFacets(options.facets)
  const {
    attributesToRetrieve = `*`,
    distinct,
    hitsPerPage,
    page = 0,
    query,
    nbPageBanners = 0,
    nbPreviousBanners = 0,
    ruleContexts,
    analyticsTags,
  } = options
  const { includeOutOfStock = getConfigOptions(`algolia.fetchOos`) } =
    options.filters || {}
  const {
    facetFilters,
    filters,
    numericFilters,
  } = formatFiltersForAlgolia(
    normalizedFilters,
    includeOutOfStock
  )
  const { facetFilters: formattedOptionalFilters } = formatFiltersForAlgolia(
    optionalFilters,
    includeOutOfStock
  )

  const offset = getOffset(
    page,
    hitsPerPage,
    nbPreviousBanners,
    options.offset
  )
  const length = getLength(hitsPerPage, nbPageBanners)

  return {
    analyticsTags: setAnalyticsTags({
      analyticsTags,
      normalizedFilters,
    }),
    normalizedFilters,
    attributesToRetrieve,
    clickAnalytics,
    distinct,
    facetFilters,
    facetingAfterDistinct: false,
    facets,
    ruleContexts: ruleContexts,
    filters,
    length,
    numericFilters,
    offset,
    optionalFilters: formattedOptionalFilters,
    sortOptionalFilters: optionalFilters,
    query: sanitize(query),
    analytics: analytics,
    userToken: window.$shoppingSessionId.value,
  }
}

function fetchProducts({
  analytics,
  clickAnalytics,
  facets,
  index,
  options = {},
  priorityOptions,
}) {
  const {
    query,
    ...searchParameters
  } = generateSearchParameters({
    analytics,
    clickAnalytics,
    facets,
    index,
    options,
    priorityOptions,
  })
  return index.search(query, omit(searchParameters, `sortOptionalFilters`, `normalizedFilters`))
}

function setAnalyticsTags({
  analyticsTags = [],
  normalizedFilters,
}) {
  return uniq([
    ...analyticsTags,
    ...(get(normalizedFilters, `categoryPageIds.includes`, [])),
  ].map(toSlug))
}

function getOffset(page, hitsPerPage, nbPreviousBanners, offset = 0) {
  if (
    typeof page === `number` &&
    typeof hitsPerPage === `number` &&
    typeof nbPreviousBanners === `number`
  ) {
    return page * hitsPerPage - nbPreviousBanners + offset
  }
}

function getLength(hitsPerPage, nbPageBanners) {
  if (
    typeof hitsPerPage === `number` &&
    typeof nbPageBanners === `number`
  ) {
    return hitsPerPage - nbPageBanners
  }
}

export function formatResults({
  format,
  optionalFilters,
  defaultCollectionSort,
  bannerOptions,
  response,
}) {
  if (!format) {
    return response
  }

  if (optionalFilters && Object.keys(optionalFilters).length) {
    const featureSortCollection = optionalFilters?.collections?.includes?.[0] || defaultCollectionSort
    const prioritizedProductIds = optionalFilters?.prioritizedProductIds?.includes || []

    // mutation. Could this lead to any potential bugs?
    response.hits.sort((a, b) => {
      const aPriority = prioritizedProductIds.indexOf(a.productId)
      const bPriority = prioritizedProductIds.indexOf(b.productId)
      const aSort = a.collections && a.collections.find(collection => collection.tag === featureSortCollection)
      const bSort = b.collections && b.collections.find(collection => collection.tag === featureSortCollection)

      if (aPriority > -1 && bPriority > -1) {
        return aPriority - bPriority
      }

      if (aPriority === -1 && bPriority > -1) {
        return 1
      }

      if (aPriority > -1 && bPriority === -1) {
        return -1
      }

      if (aSort && bSort) {
        return aSort.rank - bSort.rank
      }

      if (!aSort) {
        return 0
      }

      if (!bSort) {
        return -1
      }

      return 0
    })
  }

  if (!isNil(response.length) && !isNil(response.offset)) {
    const offset = response.offset + bannerOptions.nbPreviousBanners
    const length = response.length + bannerOptions.nbPageBanners

    response.page = Math.ceil(offset / length)
    response.nbPages = Math.ceil(response.nbHits / length)
    response.hitsPerPage = length
  }


  return {
    ...response,
    hits: response.hits.map((hit, i) => ({
      ...hit,
      queryID: response.queryID,
      offsettedPosition: i + response.offset + 1, // 1-based index
    })),
  }
}

function handleError({
  error,
}) {
  if (/Index .+ does not exist/.test(error.message)) {
    reportError(new ErrorAdditional({
      title: `Missing Alogia Index Error`,
      message: error.message,
      originalError: error,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/services/algolia.js`,
        [SENTRY_TAGS.SHOULD_NOTIFY]: true,
      },
    }))
  }
  throw new Error(error.message)
}

function getFacets(facets) {
  if (facets) {
    return facets
  }

  const FILTERSMAP = algoliaFiltersMap.get()

  return [
    ..._filter(FILTERSMAP, { filterType: `facet` }),
    ..._filter(FILTERSMAP, { filterType: `numeric` }),
  ].map(filter => filter.nameInAlgolia)
}

export function formatFiltersForAlgolia(filters, includeOutOfStock) {
  const FILTERSMAP = algoliaFiltersMap.get()
  let unfacetedFilters = ``
  let facetFilters = []
  let numericFilters = [`price.${getMetaOptions(`currencyCountry`)}.sale > 0`]

  // 1. Map through filters and work out their type using FILTERSMAP
  // 3. For each filter, depending on type, run through unfaceted, faceted or numeric function
  // 4. Assume every filter has an includes / excludes property to know how to filter

  const filterMethods = {
    boolean: booleanFilter,
    facet: facetedFilter,
    numeric: numericFilter,
    unfaceted: unfacetedFilter,
    ignore: ignore,
  }

  const filtersArray = Object.keys(filters)

  filtersArray.forEach(filter => {
    if (FILTERSMAP[filter]) {
      const filterType = FILTERSMAP[filter].filterType
      const nameInAlgolia = FILTERSMAP[filter].nameInAlgolia
      filterMethods[filterType](nameInAlgolia, filters[filter])
    }
  })

  if (!includeOutOfStock) {
    unfacetedFilters += `${unfacetedFilters ? ` AND ` : ``}availabilityFlag < 3`
  }

  return {
    facetFilters,
    filters: unfacetedFilters,
    numericFilters,
  }

  function facetedFilter(nameInAlgolia, filterOptions = {}) {
    const {
      includes = [],
      excludes = [],
    } = filterOptions

    let array = []
    includes.forEach(filter => {
      array.push(`${nameInAlgolia}:${filter}`)
    })

    excludes.forEach(filter => {
      facetFilters.push(`${nameInAlgolia}:-${filter}`)
    })

    if (array.length) {
      facetFilters.push(array)
    }
  }

  function numericFilter(nameInAlgolia, filterOptions) {
    // Assume numeric data is provided as an array = [min, max]
    // If only max, must provide null or 0 as first paramater
    const min = filterOptions[0]
    const max = filterOptions[1]

    if (min) {
      numericFilters.push(`${nameInAlgolia}>=${min}`)
    }

    if (max) {
      numericFilters.push(`${nameInAlgolia}<=${max}`)
    }
  }

  function unfacetedFilter(nameInAlgolia, filterOptions) {
    const {
      includes = [],
      excludes = [],
    } = filterOptions

    if (includes.length) {
      unfacetedFilters += `${unfacetedFilters ? ` AND ` : ``}(${includes.reduce((acc, val) => acc.concat(`${nameInAlgolia}:${formatFilterValue(val)}`), []).join(` OR `)})`
    }

    if (excludes.length) {
      unfacetedFilters += `${unfacetedFilters ? ` AND ` : ``}(${excludes.reduce((acc, val) => acc.concat(`NOT ${nameInAlgolia}:${formatFilterValue(val)}`), []).join(` AND `)})`
    }
  }

  function booleanFilter(nameInAlgolia, filterOptions) {
    unfacetedFilters += `${unfacetedFilters ? ` AND` : ``} ${nameInAlgolia} = ${filterOptions ? `1` : `0`}`
  }

  function ignore() { }
}

export function formatFilterValue(value) {
  // Algolia does not like special characters in filters
  const encodedValue = decodeURIComponent(value).replace(/[^a-zA-Z0-9 >\-\._]/g, ``)
  if (encodedValue.includes(` `)) {
    return `'${encodedValue}'`
  }
  return encodedValue
}

export function normalizeFiltersObject(filters) {
  let returnObj = {}

  if (filters) {
    const FILTERSMAP = algoliaFiltersMap.get()
    const filterKeys = Object.keys(filters)
    // check for includes array
    filterKeys.forEach(key => {
      const keyCheck = filters[key]

      if (
        typeof keyCheck === `object` &&
        !Object.prototype.hasOwnProperty.call(keyCheck, `includes`) &&
        !Object.prototype.hasOwnProperty.call(keyCheck, `excludes`) &&
        FILTERSMAP[key] &&
        FILTERSMAP[key].filterType !== `numeric` &&
        FILTERSMAP[key].filterType !== `boolean`
      ) {
        returnObj[key] = {
          includes: keyCheck,
        }
      } else {
        returnObj[key] = keyCheck
      }
    })
  }

  return returnObj
}

export function mergeFiltersObject(filtersArray) {
  return filtersArray.reduce((filtersObject, filter) => {
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        if (!filtersObject[key]) {
          filtersObject[key] = value
        } else {
          const {
            includes,
            excludes,
          } = value
          if (filtersObject[key].includes && includes) {
            filtersObject[key].includes = [...filtersObject[key].includes, ...includes]
          } else if (includes) {
            filtersObject[key].includes = includes
          }
          if (filtersObject[key].excludes && excludes) {
            filtersObject[key].excludes = [...filtersObject[key].excludes, ...excludes]
          } else if (excludes) {
            filtersObject[key].excludes = excludes
          }
        }
      })
    }
    return filtersObject
  }, {})
}

export async function getFilters(language) {
  // NOTE: hardcode the highest possible hits in lithos code (20 * 1000) and manage the
  // upper limit within the Algolia Index configs in the admin dashboard `paginationLimitedTo`
  const TOTAL_PAGES = 20
  const HITS_PER_PAGE = 1000
  const facetIndicesPrefix = getMetaOptions(`integrations.algolia.facetIndicesPrefix`)
  const siteTag = getMetaOptions(`siteTag`)
  const queries = []
  for (let i = 0; i < TOTAL_PAGES; i++) {
    queries.push({
      indexName: facetIndicesPrefix,
      hitsPerPage: HITS_PER_PAGE,
      page: i,
      facetFilters: [
        `siteTag:${siteTag}`,
        `language:${language}`,
      ],
      analytics: false,
    })
  }

  const response = await window.$content.algoliaMultipleQueries(queries)
  const hits = response?.results?.reduce((acc, item) => { return acc.concat(item.hits)}, []) || []

  // Report an error if it detects we are missing some filters from the fetch
  const totalNumberOfFilters = response?.results[0].nbHits
  if (hits.length < totalNumberOfFilters) {
    const errorMessage = `[Filters] There are some filters not fetched due to index config value for paginationLimitedTo. Fetched ${hits.length} out of total ${totalNumberOfFilters} filters.`
    console.error(errorMessage)
    reportError(new ErrorAdditional({
      title: `[Algolia] There are some filters not fetched due to index config value for paginationLimitedTo.`,
      message: errorMessage,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/services/algolia.js`,
        [SENTRY_TAGS.SHOULD_NOTIFY]: true,
      },
    }))
  }

  return hits
}

export const algoliaFiltersMap = {
  get() {
    if (this.value === undefined) {
      this.create()
    }

    return this.value
  },
  create() {
    this.value = CREATEFILTERSMAP()
  },
}

// filterType: 'boolean' === unfacetedFilter
export function CREATEFILTERSMAP() {
  return {
    brand: {
      filterType: `facet`,
      nameInAlgolia: `brand.tag`,
      webLabel: webLabels.brand,
    },
    categories: {
      filterType: `facet`,
      nameInAlgolia: `categories.tag`, // There is a facet which is categories.name
    },
    categoryPageIds: {
      filterType: `unfaceted`,
      nameInAlgolia: `categoryPageIds`,
    },
    collections: {
      filterType: `facet`,
      nameInAlgolia: `collections.tag`,
    },
    color: {
      categoryLabel: `colorGroup`,
      filterType: `facet`,
      nameInAlgolia: `color.tag`,
      webLabel: webLabels.color,
    },
    priceRange: {
      currency: true,
      filterType: `numeric`,
      nameInAlgolia: `price.${getMetaOptions(`currencyCountry`)}.sale`,
      step: getMetaOptions(`currencyStep`),
      webLabel: webLabels.price,
    },
    size: {
      filterType: `facet`,
      nameInAlgolia: `size.tag`,
      webLabel: webLabels.size,
    },
    slug: {
      filterType: `unfaceted`,
      nameInAlgolia: `slug`,
    },
    productId: {
      filterType: `unfaceted`,
      nameInAlgolia: `productId`,
    },
    prioritizedProductIds: {
      filterType: `facet`,
      nameInAlgolia: `productId`,
    },
    onSale: {
      filterType: `boolean`,
      nameInAlgolia: `price.${getMetaOptions(`currencyCountry`)}.onSale`,
    },
    includeOutOfStock: {
      filterType: `ignore`,
    },
    ...getConfigOptions(`customFilters`),
  }
}

export function getDistinct(isBSC) {
  if (isBSC === false) {
    return true
  }

  if (isBSC === true) {
    return false
  }

  return undefined
}
